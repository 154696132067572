import React from 'react'
import * as Icon from 'react-feather'


export const ResponseStatus = {
    SUCCESS: "Success",
    FAILED: "Failed"
}

export const MONTH_TH = [
    { short: 'ม.ค.', full: 'มกราคม' },
    { short: 'ก.พ.', full: 'กุมภาพันธ์' },
    { short: 'มี.ค.', full: 'มีนาคม' },
    { short: 'เม.ย.', full: 'เมษายน' },
    { short: 'พ.ค.', full: 'พฤษภาคม' },
    { short: 'มิ.ย.', full: 'มิถุนายน' },
    { short: 'ก.ค.', full: 'กรกฎาคม' },
    { short: 'ส.ค.', full: 'สิงหาคม' },
    { short: 'ก.ย.', full: 'กันยายน' },
    { short: 'ต.ค.', full: 'ตุลาคม' },
    { short: 'พ.ย.', full: 'พฤษจิกายน' },
    { short: 'ธ.ค.', full: 'ธันวาคม' }
]

export const WEEKDAY_TH = [
    { short: 'อา', full: 'อาทิตย์' },
    { short: 'จ', full: 'จันทร์' },
    { short: 'อ', full: 'อังคาร' },
    { short: 'พ', full: 'พุธ' },
    { short: 'พฤ', full: 'พฤหัสบดี' },
    { short: 'ศ', full: 'ศุกร์' },
    { short: 'ส', full: 'เสาร์' }
]

export const SHOP_STATUS = {
    INACTIVE: 'Inactive',
    OPEN: 'Open',
    CLOSED: 'Closed'
}

export const COLOR = {
    TDM_YELLOW: '#FED56C',
    TMD_GREY: '#7F7F7F',
    GREEN: '#54DA46',
    DARKGREEN: '#159015',
    YELLOW: '#FAB900',
    RED: '#FF7C80',
    DARKGREY: '#8C8C8C',
    GREY: '#DCDCDC',
    SILVER: '#B4B4B4',
    LIGHTGREY: '#F7F7F7',
    BLUE: '#40BAD2',
    REDLIGHT: '#FF8A8D',
}

export const GRAPH_COLOR = {
    TEMP: {
        CABINET: '#5DADE2',
        EVAPORATOR: '#35F2F1',
        SET_POINT: '#5D6D7E',
    },
    STATUS: {
        FAN: '#35F2F1',
        DEFROST: '#FFA07A',
        COMPRESSOR: '#0DFF7A',
        CONNECTION_LOST: '#5D6D7E'
    },
    EQUIPMENT: {
        AIR: '#35F2F1',
        REFRIG: '#5DADE2',
        OTHER: '#00cc99'
    },
    ALERT: '#FF0000',
    COMP: {
        CAPACITY: '#0DFF7A'
    },
    PRESSURE: {
        HIGH: '#FF107A',
        LOW: '#FAB900'
    },
    PERFORMANCE: {
        FAN: '#35F2F1',
        COMP: '#0DFF7A',
        OIL: '#FFA07A',
    },
    CURRENT: {
        C1: '#5DADE2',
        C2: '#6D9EEB',
        C3: '#3D85C6',
    },
    SUPERHEAT: {
        HEAT: '#FF107A'
    },
    CDU: {
        GRAPH_1: ['#376787', '#4a8ab4', '#8dc5ea', '#bedef3'],
        GRAPH_2: ['#1f9190', '#2fd9d8', '#5df4f3', '#c2fbfa']
    },
    RPS: {
        C1: '#5DADE2',
        C2: '#6D9EEB',
        C3: '#3D85C6',
    },
}

export const HEALTHSCORECOLOR = {
    HEALTHY: '#00CC99',
    RISK: '#FFD000',
    HIGHRISK: '#FFA500',
    UNHEALTHY: '#FF0040',
    NOVALUE: '#878787',
    DANGER: '#CC0033',
}

export const ALERTCOLOR = {
    C0: '#FFE6EC',
    C50: '#FFCCD9',
    C100: '#FF99B3',
    C200: '#FF4D79',
    C500: '#FF0040',
    C800: '#BD0016'
}

export const FAN_IMAGE = require('../assets/img/old/fan.png')
export const COMPRESSOR_IMAGE = require('../assets/img/old/compressor.png')
export const DEFROST_IMAGE = require('../assets/img/old/defrost.png')
export const INTERNET_IMAGE = require('../assets/img/old/internet.png')
export const FAN_MODE_IMAGE = require('../assets/img/old/fan_mode.png')
export const DRY_MODE_IMAGE = require('../assets/img/old/dry_mode.png')
export const COOLING_MODE_IMAGE = require('../assets/img/old/cooling_mode.png')
export const AMBIENT_TEMP_IMAGE = require('../assets/img/old/ambient_temp.png')
export const PRESSURE_GAUGE_IMAGE = require('../assets/img/old/pressure_gauge.png')
export const TEMP_PRESSURE_GAUGE_IMAGE = require('../assets/img/old/temp_pressure_gauge.png')

export const AIR_TYPE = 'AIR'
export const CDU_TYPE = 'CDU'
export const REFRIG_TYPE = 'REFRIG'
export const REFRIG_CDU_TYPE = 'REFRIG_CDU'

export const CATEGORY_INFO = {
    [AIR_TYPE]: {
        text: "Air Conditioner"
    },
    [CDU_TYPE]: {
        text: "Condensing Unit"
    },
    [REFRIG_TYPE]: {
        text: "Refrigerated Remote Cabinet"
    },
    [REFRIG_CDU_TYPE]: {
        text: "Refrigerated Plug-in Cabinet"
    }
}

export const ASSET_TYPE = {
    'shop': {
        icon: require('../assets/img/old/shop.png')
    },
    'Condensing Unit': {
        icon: require('../assets/img/old/condensing.png'),
        style: { height: '2em', marginTop: '1em' },
        data: {
            temp: { min: 25, value: 36, max: 45 },
            power: { min: 1.7, value: 1.79, max: 2.08 }
        },
        type: CDU_TYPE
    },
    'CDU ZXD': {
        icon: require('../assets/img/old/cdu_zxd.png'),
        data: {
            temp: { min: 25, value: 36, max: 45 },
            power: { min: 1.7, value: 1.79, max: 2.08 }
        },
        type: CDU_TYPE
    },
    'CDU Optyma': {
        icon: require('../assets/img/old/cdu_optyma.png'),
        style: { height: '2.5em', marginTop: '0.5em' },
        data: {
            temp: { min: 25, value: 36, max: 45 },
            power: { min: 1.7, value: 1.79, max: 2.08 }
        },
        type: CDU_TYPE
    },
    'CDU': {
        icon: require('../assets/img/old/cdu.png'),
        data: {
            temp: { min: 25, value: 36, max: 45 },
            power: { min: 1.7, value: 1.79, max: 2.08 }
        },
        type: CDU_TYPE
    },
    'Multi-Condensing': {
        icon: require('../assets/img/old/multi_condensing.png'),
        style: { height: '2em', marginTop: '1em' },
        data: {
            temp: { min: 25, value: 40, max: 45, color: '#FAB900' },
            power: { min: 1.7, value: 3.06, max: 4.2 }
        }
    },
    'Open Type Plug-in': {
        icon: require('../assets/img/old/open_type_plugin.png'),
        data: {
            temp: { min: 2, value: 2.4, max: 4 },
            power: { min: 0.08, value: 0.13, max: 0.25 }
        },
        type: REFRIG_CDU_TYPE
    },
    'Open Type Remote': {
        icon: require('../assets/img/old/open_type_black.png'),
        data: {
            temp: { min: 2, value: 3.5, max: 4, color: '#FAB900' },
            power: { min: 0.08, value: 0.11, max: 0.25 }
        }
    },
    'Under Counter': {
        icon: require('../assets/img/old/undercounter.png'),
        style: { height: '2em', marginTop: '1em' },
        data: {
            temp: { min: 2, value: 2.6, max: 4 },
            power: { min: 1.8, value: 2.3, max: 2.5, color: '#FAB900' }
        },
        type: REFRIG_CDU_TYPE
    },
    'Vault Room': {
        icon: require('../assets/img/old/vault_room.jpg'),
        style: { height: '1.8em' },
        data: {
            temp: { min: 2, value: 2.6, max: 4 },
            power: { min: 1.8, value: 2.3, max: 2.5, color: '#FAB900' }
        }
    },
    'Upright Freezer': {
        icon: require('../assets/img/old/upright.png'),
        style: { height: '3em' },
        data: {
            temp: { min: 2, value: 2.6, max: 4 },
            power: { min: 1.8, value: 2.3, max: 2.5, color: '#FAB900' }
        },
        type: REFRIG_CDU_TYPE
    },
    'Upright Freezer 2 Door': {
        icon: require('../assets/img/old/upright_2door.png'),
        style: { height: '3em' },
        data: {
            temp: { min: 2, value: 2.6, max: 4 },
            power: { min: 1.8, value: 2.3, max: 2.5, color: '#FAB900' }
        },
        type: REFRIG_CDU_TYPE
    },
    'Air': {
        icon: require('../assets/img/old/air.png'),
        data: {
            temp: { min: 2, value: 2.6, max: 4 },
            power: { min: 1.8, value: 2.3, max: 2.5, color: '#FAB900' }
        },
        type: AIR_TYPE
    },
    'Air Ceiling': {
        icon: require('../assets/img/old/air_ceiling.png'),
        data: {
            temp: { min: 2, value: 2.6, max: 4 },
            power: { min: 1.8, value: 2.3, max: 2.5, color: '#FAB900' }
        },
        type: AIR_TYPE
    },
    'Air Ceiling 4': {
        icon: require('../assets/img/old/air_ceiling_4.png'),
        style: { height: '2.3em', marginButtom: '0.5em' },
        data: {
            temp: { min: 2, value: 2.6, max: 4 },
            power: { min: 1.8, value: 2.3, max: 2.5, color: '#FAB900' }
        },
        type: AIR_TYPE
    },
    'Island': {
        icon: require('../assets/img/old/island.png'),
        data: {
            temp: { min: 2, value: 2.6, max: 4 },
            power: { min: 1.8, value: 2.3, max: 2.5, color: '#FAB900' }
        },
        type: REFRIG_CDU_TYPE
    },
    'Glass Door 3D': {
        icon: require('../assets/img/old/glass_door_3d.jpg'),
        data: {
            temp: { min: 2, value: 2.6, max: 4 },
            power: { min: 1.8, value: 2.3, max: 2.5, color: '#FAB900' }
        }
    },
    'Glass Door 5D': {
        icon: require('../assets/img/old/glass_door_5d.jpg'),
        style: { height: '2em' },
        data: {
            temp: { min: 2, value: 2.6, max: 4 },
            power: { min: 1.8, value: 2.3, max: 2.5, color: '#FAB900' }
        }
    },
    'Open Showcase': {
        icon: require('../assets/img/old/open_showcase.jpg'),
        data: {
            temp: { min: 2, value: 2.6, max: 4 },
            power: { min: 1.8, value: 2.3, max: 2.5, color: '#FAB900' }
        }
    },
    'Undefined': {
        icon: require('../assets/img/old/equipment.png')
    }
}

export const ASSET_DATA = {
    2201: {
        health: 98,
        fail: 3
    },
    2202: {
        health: 96,
        fail: 7
    },
    2203: {
        health: 97,
        fail: 6
    },
    2204: {
        health: 95,
        fail: 10
    },
    2205: {
        health: 95,
        fail: 9
    }
}

export const DEFAULT_PAGE_SIZE = 10.0

export const THEME_CONFIG = {
    layout: 'vertical', // options[String]: 'vertical'(default), 'horizontal'
    theme: 'light', // options[String]: 'light'(default), 'dark', 'semi-dark'
    sidebarCollapsed: false, // options[Boolean]: true, false(default)
    navbarColor: 'default', // options[String]: default / primary / success / danger / info / warning / dark
    navbarType: 'floating', // options[String]: floating(default) / static / sticky / hidden
    footerType: 'hidden', // options[String]: static(default) / sticky / hidden
    disableCustomizer: true, // options[Boolean]: true, false(default)
    hideScrollToTop: false, // options[Boolean]: true, false(default)
    menuTheme: 'primary', // options[String]: primary / success / danger / info / warning / dark
    direction: 'ltr' // options[String] : ltr / rtl
}

export const MENU = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'item',
        icon: <Icon.Layout size={20} />,
        permissions: ['admin'],
        navLink: '/dashboard',
        collapsed: true
    },
    {
        id: 'shop',
        title: 'Shop',
        type: 'item',
        icon: <Icon.ShoppingBag size={20} />,
        permissions: ['admin'],
        navLink: '/shop'
    },
    {
        id: 'equipment',
        title: 'Equipment',
        type: 'item',
        icon: <Icon.MinusSquare size={20} />,
        permissions: ['admin'],
        navLink: '/equipment'
    },
    {
        id: 'alarm',
        title: 'Alarm',
        type: 'item',
        icon: <Icon.Bell size={20} />,
        permissions: ['admin'],
        navLink: '/alarm'
    },
    {
        id: 'myaccount',
        title: 'My Account',
        type: 'item',
        icon: <Icon.User size={20} />,
        permissions: ['admin'],
        navLink: '/my-account'
    },
    {
        id: 'setting',
        title: 'Setting',
        type: 'collapse',
        icon: <Icon.Settings size={20} />,
        children: [
            {
                id: 'shopmaster',
                title: 'Shop Master',
                type: 'item',
                icon: <Icon.Circle size={12} />,
                permissions: ['admin'],
                navLink: '/shop-brand'
            },
            {
                id: 'equipmentmodel',
                title: 'Equipment Model',
                type: 'item',
                icon: <Icon.Circle size={12} />,
                permissions: ['admin'],
                navLink: '/equipment-model'
            },
            {
                id: 'user',
                title: 'User',
                type: 'collapse',
                icon: <Icon.Circle size={12} />,
                permissions: ['admin'],
                children: [
                    {
                        id: 'user-list',
                        title: 'List',
                        type: 'item',
                        icon: <Icon.Circle size={12} />,
                        permissions: ['admin'],
                        navLink: '/user'
                    },
                    {
                        id: 'user-role',
                        title: 'Role',
                        type: 'item',
                        icon: <Icon.Circle size={12} />,
                        permissions: ['admin'],
                        navLink: '/user-role'
                    }
                ]
            }
        ]
    }
]

export const ActionMode = {
    NEW: 'New',
    DISPLAY: 'Display',
    COPY: 'Copy',
    CHANGE: 'Change',
    DELETE: 'Delete'
}

export const DefaultRoleModule = [
    {
        roleId: '',
        moduleName: "Shop",
        moduleIndex: 1,
        modulePath: "shop",
        isCreate: false,
        isChange: false,
        isDisplay: false,
        isDelete: false,
        all: false
    },
    {
        roleId: '',
        moduleName: "Equipment",
        moduleIndex: 2,
        modulePath: "equipment",
        isCreate: false,
        isChange: false,
        isDisplay: false,
        isDelete: false,
        all: false
    },
    {
        roleId: '',
        moduleName: "Setting > Shop Master",
        moduleIndex: 3,
        modulePath: "shopmaster",
        isCreate: false,
        isChange: false,
        isDisplay: false,
        isDelete: false,
        all: false
    },
    {
        roleId: '',
        moduleName: "Setting > Equipment Model",
        moduleIndex: 4,
        modulePath: "equipmentmodel",
        isCreate: false,
        isChange: false,
        isDisplay: false,
        isDelete: false,
        all: false
    },
    {
        roleId: '',
        moduleName: "Setting > User",
        moduleIndex: 5,
        modulePath: "user",
        isCreate: false,
        isChange: false,
        isDisplay: false,
        isDelete: false,
        all: false
    }
]

export const PERIOD = {
    HOURLY: 'Hourly',
    DAILY: 'Daily',
    MONTHLY: 'Monthly'
}

export const GRAPHPARAMETER = {
    REFRIG_Temp: [1, 2, 3],
    REFRIG_Status: [7, 8, 11, 12],
    REFRIG_CDU_Temp: [1, 2, 3],
    REFRIG_CDU_Status: [7, 8, 11, 12],
    REFRIG_CDU_Performance: [17],
    CDU_Temp: [1, 4, 5, 6],
    CDU_Pressure: [20, 21],
    CDU_Status: [7, 9, 10],
    CDU_Current: [22, 23, 24],
    CDU_Performance: [16, 18, 19],
    CDU_Superheat: [25],
    AIR_Temp: [1, 3],
    AIR_Status: [7, 13, 14, 15],
    AIR_Current: [38],
    AIR_RPS: [39],
}

export const UNIT_GROUP = {
    TEMP: 'Temp',
    STATUS: 'Status',
    PRESSURE: 'Pressure',
    PERFORMANCE: 'Performance',
    CURRENT: 'Current',
    SUPERHEAT: 'Superheat',
    RPS: 'RPS',
}

export const HEALTH_SCORE_PARAMETER = {
    color: HEALTHSCORECOLOR.HEALTHY,
    description: 'Health Score',
    parameterName: 'healthScore',
    unit: '%',
    unitGroup: 'Health Score',
}

export const ALERT_PARAMETER = {
    color: GRAPH_COLOR.ALERT,
    description: 'Alert',
    parameterName: 'countAlert',
    unit: 'Min',
    unitGroup: 'Alert',
}

export const TAB = {
    HISTORICAL: 'HISTORICAL_TAB',
    NOW: 'NOW_TAB'
}

export const SortBy ={
    DESC: 'desc',
    ASC: 'asc'
}
